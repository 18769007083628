<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'Login',
  mounted () {
    if (this.$route.query.returnUrl) {
      this.$cookies.set('connect-redirect', this.$route.query.returnUrl,'30d','/');
    }
    this.$auth.loginWithRedirect({
      appState: { targetUrl: '/redirect' }
    });
  }
}
</script>

<style lang="scss"></style>
